.active {
  @apply flex items-center px-4 py-2 text-gray-700 rounded bg-gray-200 transition-colors duration-200 transform rounded  hover:bg-gray-200 ;
}

.in-active {
  @apply flex items-center px-4 py-2 text-gray-700 rounded transform rounded  hover:bg-gray-200 hover:text-gray-700;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body;
  }
}



